(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/floating-action-button/assets/javascripts/floating-action-button.js') >= 0) return;  svs.modules.push('/components/lb-ui/floating-action-button/assets/javascripts/floating-action-button.js');

'use strict';

const _excluded = ["startY", "onClick", "children", "className", "willReceiveStartPos"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  useEffect,
  useRef,
  useCallback
} = React;
const MARGIN_BASE = 12;
const BUTTON_SIZE = 48;
const THRESHOLD_CLICK = 10;
const FloatingActionButton = _ref => {
  let {
      startY: startYProp,
      onClick,
      children,
      className,
      willReceiveStartPos
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  const dragging = useRef(false);
  const x = useRef(MARGIN_BASE / 2, 0);
  const y = useRef(0);
  const ref = useRef();
  const currentX = useRef(0);
  const currentY = useRef(0);
  const startX = useRef(0);
  const startY = useRef(0);
  const previousSide = useRef('right');
  const isMouseDown = useRef();
  const startXDrag = useRef(0);
  const startYDrag = useRef(0);
  const currentXDrag = useRef(0);
  const currentYDrag = useRef(0);
  const update = () => {
    if (!ref.current) {
      return;
    }
    x.current = Math.round(currentX.current - startX.current);
    y.current = Math.round(currentY.current - startY.current);
    ref.current.style.transform = "translate3d(".concat(x.current, "px, ").concat(y.current, "px, 0)");
  };
  useEffect(() => {
    const goToDefaultPosition = () => {
      const {
        innerWidth,
        innerHeight
      } = window;
      startX.current = 0;
      startY.current = 0;
      currentX.current = innerWidth - BUTTON_SIZE - MARGIN_BASE / 2;
      currentY.current = (startYProp ? startYProp : innerHeight / 2) - BUTTON_SIZE / 2;
      update();
      ref.current.getBoundingClientRect();
      ref.current.classList.add('floating-action-button--init');
    };
    goToDefaultPosition();
  }, [startYProp]);
  useEffect(() => {
    if (startYProp) {
      startY.current = 0;
      currentY.current = startYProp - BUTTON_SIZE / 2;
      requestAnimationFrame(update);
    }
  }, [startYProp]);
  const handleResize = useCallback(() => {
    startX.current = 0;
    currentX.current = previousSide.current === 'left' ? MARGIN_BASE / 2 : window.innerWidth - BUTTON_SIZE - MARGIN_BASE / 2;
    if (window.innerHeight < y.current) {
      startY.current = 0;
      currentY.current = window.innerHeight - BUTTON_SIZE - MARGIN_BASE / 2;
    }
    requestAnimationFrame(update);
  }, []);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
  useEffect(() => {
    const handleOrientationChange = () => {
      const handleResizeOnce = () => {
        handleResize();
        window.removeEventListener('resize', handleResizeOnce);
      };
      window.addEventListener('resize', handleResizeOnce);
    };
    window.addEventListener('orientationchange', handleOrientationChange);
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [handleResize]);
  const handleOnMouseDown = event => {
    isMouseDown.current = true;
    startX.current = event.currentTarget.offsetWidth / 2;
    startY.current = event.currentTarget.offsetHeight / 2;
    startXDrag.current = event.pageX;
    startYDrag.current = event.pageY;
    currentXDrag.current = event.pageX;
    currentYDrag.current = event.pageY;
  };
  const getDragDistance = () => {
    const dx = Math.abs(startXDrag.current - currentXDrag.current);
    const dy = Math.abs(startYDrag.current - currentYDrag.current);
    const distance = Math.sqrt(dx * dx + dy * dy);
    return distance;
  };
  useEffect(() => {
    const handleMouseMove = event => {
      if (isMouseDown.current) {
        currentX.current = event.pageX;
        currentY.current = event.pageY;
        currentXDrag.current = event.pageX;
        currentYDrag.current = event.pageY;
        const draggedDistance = getDragDistance();
        if (draggedDistance > 0) {
          document.body.classList.add('floating-action-button-dragging');
        } else {
          document.body.classList.remove('floating-action-button-dragging');
        }
        requestAnimationFrame(update);
      }
    };
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  const fixPositionAfterDrag = useCallback(() => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight - BUTTON_SIZE - MARGIN_BASE / 2;
    startX.current = 0;
    startY.current = 0;
    currentX.current = x.current < viewportWidth / 2 ? MARGIN_BASE / 2 : viewportWidth - BUTTON_SIZE - MARGIN_BASE / 2;
    currentY.current = Math.max(MARGIN_BASE / 2, Math.min(y.current, viewportHeight));
    previousSide.current = x.current < viewportWidth / 2 ? 'left' : 'right';
    requestAnimationFrame(update);
  }, []);
  useEffect(() => {
    const handleClick = () => {
      fixPositionAfterDrag();
      isMouseDown.current = false;
      document.body.classList.remove('floating-action-button-dragging');
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [fixPositionAfterDrag]);
  const handleOnTouchStart = event => {
    startX.current = event.touches[0].pageX - x.current;
    startY.current = event.touches[0].pageY - y.current;
  };
  const handleOnTouchMove = event => {
    if (!dragging.current) {
      dragging.current = true;
    }
    currentX.current = event.touches[0].pageX;
    currentY.current = event.touches[0].pageY;
    requestAnimationFrame(update);
  };
  const handleOnTouchEnd = () => {
    if (dragging.current) {
      fixPositionAfterDrag();
      dragging.current = false;
    }
  };
  useEffect(() => {
    const handleTouchMove = e => {
      if (dragging.current) {
        e.preventDefault();
      }
    };
    window.addEventListener('touchmove', handleTouchMove, {
      passive: false
    });
    return () => {
      window.removeEventListener('touchmove', handleTouchMove, {
        passive: false
      });
    };
  }, []);
  const handleClick = event => {
    const distanceDragged = getDragDistance();
    if (distanceDragged <= THRESHOLD_CLICK) {
      if (onClick) {
        onClick(event);
      }
    }
  };
  const classList = ['floating-action-button', 'qa-floating-action-button'];
  if (willReceiveStartPos && !startYProp) {
    return null;
  }
  if (className) {
    classList.push(className);
  }
  return React.createElement("button", _extends({
    className: classList.join(' '),
    "data-testid": "floating-action-button",
    onClick: handleClick,
    onMouseDown: handleOnMouseDown,
    onTouchEnd: handleOnTouchEnd,
    onTouchMove: handleOnTouchMove,
    onTouchStart: handleOnTouchStart,
    ref: ref,
    type: "button"
  }, rest), React.createElement("div", {
    className: "floating-action-button-content"
  }, children));
};
FloatingActionButton.BUTTON_SIZE = BUTTON_SIZE;
FloatingActionButton.MARGIN_BASE = MARGIN_BASE;
setGlobal('svs.components.lbUi.floatingActionButton.FloatingActionButton', FloatingActionButton);

 })(window);